import { Tab } from "@headlessui/react";
import { gql } from "graphql-request";
import useGraphqlQuery from "../hooks/useGraphqlQuery";
import useStores from "../hooks/useStores";
import DateRangeInput from "./date-range-input";
import ProductLevelTable from "./product-level-table";
import StockLevelRow from "./stock-level-row";
import { graphqlFetcher } from "../utils/fetchers";
import { useEffect, useState } from "react";

export default function ({ productExportCache }) {
  if (!productExportCache) {
    return <Tab.Panel />;
  }
  const [stores] = useStores();

  const [storeIndex, setStoreIndex] = useState(0);
  const [stockLevelData, setStockLevelData] = useState({});

  const query =
    productExportCache &&
    gql`
      query ($query: String!) {
        products(query: $query, first: 1) {
          nodes {
            variants(first: 25) {
              nodes {
                id
                sku
              }
            }
          }
        }
      }
    `;

  const variables = {
    query: `sku:${productExportCache.sku}`,
  };

  const { data, isLoading, isError } = useGraphqlQuery(
    stores?.[0].subdomain,
    undefined,
    query,
    variables
  );

  useEffect(() => {
    if(data) {
      if (data.products.nodes[0]) {
        setStockLevelData(data);
      } else {
        setStoreIndex(prevIndex => prevIndex + 1);
      }
    }
  }, [data]);

  // when storeIndex has changed, means no data in the current store, continue to the next store
  useEffect(() => {
    if(stores && stores[storeIndex]) {
      const fetchData = async (subdomain, apiVersion, query, variables) => {
        const resultData = await graphqlFetcher([subdomain, apiVersion, query, variables]);
  
        if(resultData.products.nodes[0]) {
          setStockLevelData(resultData);
        } else {
          setStoreIndex(prevIndex => prevIndex + 1);
        }
      };

      fetchData(stores?.[storeIndex].subdomain, undefined, query, variables);
    }
  }, [storeIndex]);

  if (!stockLevelData || !stores) {
    return null;
  }

  return (
    <Tab.Panel>
      <table className="min-w-full">
        <thead className="sticky top-0 z-10 bg-white">
          <tr>
            <th />
            <th colSpan={4}>SZ Direct</th>
            <th colSpan={3}>US East Warehouse</th>
            <th colSpan={3}>US West Warehouse</th>
          </tr>
          <tr>
            <th>SKU</th>
            <th>Quantity Available</th>
            <th>Shipped in Last 7D</th>
            <th>Shipped in Last 28D</th>
            <th>Shipped in Last 42D</th>
            <th>Quantity Available</th>
            <th>Shipped in Last 7D</th>
            <th>Shipped in Last 28D</th>
            <th>Shipped in Last 42D</th>
            <th>Quantity Available</th>
            <th>Shipped in Last 7D</th>
            <th>Shipped in Last 28D</th>
            <th>Shipped in Last 42D</th>
          </tr>
        </thead>
        <tbody>
          {stockLevelData?.products?.nodes[0]?.variants?.nodes?.map(({ sku }) => (
            <StockLevelRow key={sku} sku={sku} />
          ))}
        </tbody>
      </table>
    </Tab.Panel>
  );
}
